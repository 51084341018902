import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addLeadingZerosToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import {
  isConfused,
  isCTM,
  isDog,
  petAgeIsEqualToTwoMonths,
  shouldAggsQuoteShowAdditionalQuestions,
} from 'businessLogic/aggregators';
import {
  getYoungestPetAgeThresholdDate,
  useFirstEligibleStartDateFromToday,
} from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import FormFooter from 'components/FormFooter';
import QuestionField from 'components/QuestionField';
import DogBreedQuestions from 'forms/AboutYourPetsForm/AboutYourPet/DogBreedQuestions';
import { usePetDetailsOptions } from 'forms/AboutYourPetsForm/usePetsDetailsOptions';
import CoverStartDateQuestion from 'forms/AboutYourPolicyForm/CoverStartDateQuestion';
import SectionHeading from 'forms/SectionHeading';
import {
  PageTitle,
  trackFieldError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { getAnalyticsDescriptionForInput } from 'helpers/getAnalyticsDescriptionForInput';
import getPetIdForInput from 'helpers/getPetId';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { updateItem } from 'helpers/repeatableForm';
import { CurrentQuote, useCurrentQuote } from 'helpers/useCurrentQuote';
import { useAdditionalQuestionsShown } from 'state/formData/additionalQuestionsShown';
import { Pet, PetsDetails, PetWithKey, usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import useAdditionalQuestionsPageQuestions from './questions';
import { CoverStartDateDescription, CoverStartDateSubheading } from './styles';
import useAdditionalQuestionsRules from './validation';

type AdditionalQuestionsFormData = {
  csPetAboutYouAndYourPet: {
    next_button_text: string;
  };
  csPetAggregators: {
    additional_questions: {
      cover_start_date_subheading: string;
      cover_start_date_description: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      next_button_text
    }
    csPetAggregators {
      additional_questions {
        cover_start_date_subheading
        cover_start_date_description
      }
    }
  }
`;

type AdditionalQuestionsFormProps = {
  moveNext: () => void;
};

const AdditionalQuestionsForm: React.FC<AdditionalQuestionsFormProps> = ({
  moveNext,
}) => {
  const {
    csPetAboutYouAndYourPet: { next_button_text },
    csPetAggregators: { additional_questions },
  } = useStaticQuery<AdditionalQuestionsFormData>(query);

  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const [
    additionalQuestionsShown,
    updateAdditionalQuestionsShown,
  ] = useAdditionalQuestionsShown();
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const quote = useCurrentQuote();
  const [initialQuote] = useState(quote);
  const [firstEligibleStartDate] = useFirstEligibleStartDateFromToday();

  const updateCoverStartDate = (pets: Pet[]): Date => {
    const thresholdDate = getYoungestPetAgeThresholdDate(pets);
    if (thresholdDate && thresholdDate > new Date(policyDetails.coverStartDate)) {
      return firstEligibleStartDate;
    }
    return new Date(policyDetails.coverStartDate);
  };

  /* use petDetails (instead of petsDetails),
  so that getWarning() getError() could scroll to the correct element if info is missing.
  See getPetId() - petDetails[${index}].${input} */
  const [petDetails, updatePetsDetails] = usePetsDetails();

  const petRules = useAdditionalQuestionsRules();

  const questions = useAdditionalQuestionsPageQuestions(petDetails[0]);

  const {
    getError,
    getWarning,
    showValidation,
    validateOnSubmit,
  } = useValidationWithWarnings<{ petDetails: PetsDetails }>(
    { petDetails },
    petRules.errors,
    petRules.warnings,
    trackFieldError
  );

  const { petSectionHeading } = usePetDetailsOptions();

  const generateSectionLabel = (petIndex: number): string => {
    const { petName } = petDetails[petIndex];

    return petNameReplacer(petName, petSectionHeading);
  };

  const shouldShowAgeQuestion = (
    dateOfBirth: Date | undefined,
    index: number
  ): boolean => {
    if (petAgeIsEqualToTwoMonths(dateOfBirth)) {
      if (!additionalQuestionsShown.ageQuestion?.includes(index)) {
        updateAdditionalQuestionsShown({
          ageQuestion: [...(additionalQuestionsShown.ageQuestion ?? []), index],
        });
      }
      return true;
    }
    return false;
  };

  const ShouldShowBreedQuestion = (
    currentQuote: CurrentQuote,
    index: number
  ): boolean => {
    if (
      currentQuote.petInfos &&
      !isCTM(quote) &&
      isConfused(quote) &&
      isDog(currentQuote.petInfos[index])
    ) {
      if (!additionalQuestionsShown.breedQuestion?.includes(index)) {
        updateAdditionalQuestionsShown({
          breedQuestion: [...(additionalQuestionsShown.breedQuestion ?? []), index],
        });
      }
      return true;
    }
    return false;
  };

  const [hasShownCalendar, setHasShownCalendar] = useState(new Set());

  const shouldShowCalendar = (index: number): boolean => {
    if (
      (!!getWarning('petDetails', ['petDob', index]) &&
        !getError('petDetails', ['petDob', index])) ||
      hasShownCalendar.has(index)
    ) {
      if (!hasShownCalendar.has(index)) setHasShownCalendar(hasShownCalendar.add(index));
      return true;
    }
    return false;
  };

  if (
    !petDetails[0].petIsEligible &&
    petDetails[0].petType === petType_DOG &&
    petDetails[0].petInGoodHealth
  ) {
    updatePetsDetails(updateItem(petDetails, 0, { petIsEligible: true }));
  }

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      {petDetails.map(
        (petDetail, index) =>
          quote.petInfos &&
          shouldAggsQuoteShowAdditionalQuestions(quote) && (
            <div id={`petDetails[${index}]`}>
              <SectionHeading heading={generateSectionLabel(index)} />
              {quote.petInfos && (
                <>
                  {shouldShowAgeQuestion(parseDate(quote.petInfos[index].dob), index) && (
                    <QuestionField
                      question={questions.petDob}
                      errorText={getError('petDetails', ['petDob', index])}
                      warningText={getWarning('petDetails', ['petDob', index])}
                      maskQuestionData>
                      <DateInput
                        id={getPetIdForInput(index)('petDob')}
                        value={petDetail.petDob}
                        onChange={(value) => {
                          updatePetsDetails(
                            updateItem(petDetails, index, { petDob: value })
                          );
                          updatePolicyDetails({
                            coverStartDate: updateCoverStartDate([
                              petDetail,
                            ]).toISOString(),
                          });
                          showValidation('petDetails', ['petDob', index]);
                        }}
                        onBlur={() => {
                          updatePetsDetails(
                            updateItem(petDetails, index, {
                              petDob: addLeadingZerosToDateValue(petDetail.petDob),
                            })
                          );
                          updatePolicyDetails({
                            coverStartDate: updateCoverStartDate([
                              petDetail,
                            ]).toISOString(),
                          });
                          showValidation('petDetails', ['petDob', index]);
                          trackFormTextFieldFocus(
                            getAnalyticsDescriptionForInput(index, 'Pet dob')
                          );
                        }}
                        onFocus={trackFormTextFieldFocus(
                          getAnalyticsDescriptionForInput(index, 'Pet dob')
                        )}
                      />
                    </QuestionField>
                  )}
                  {shouldShowCalendar(index) && (
                    <>
                      <CoverStartDateSubheading>
                        {additional_questions.cover_start_date_subheading}
                      </CoverStartDateSubheading>
                      {!!getWarning('petDetails', ['petDob', index]) && (
                        <CoverStartDateDescription>
                          {additional_questions.cover_start_date_description}
                        </CoverStartDateDescription>
                      )}
                      <CoverStartDateQuestion
                        minDate={firstEligibleStartDate}
                        disableCalendar={!!getError('petDetails', ['petDob', index])}
                      />
                    </>
                  )}
                </>
              )}
              {initialQuote.petInfos && ShouldShowBreedQuestion(initialQuote, index) && (
                <DogBreedQuestions
                  index={index}
                  petDetails={petDetail}
                  updatePetDetails={(update: Partial<PetWithKey>) =>
                    updatePetsDetails(updateItem<PetWithKey>(petDetails, index, update))
                  }
                  formValidation={{ getError, showValidation }}
                  dogBreedReferenceData={dogBreedsRefData}
                  pageTitle={PageTitle.AdditionalQuestions}
                />
              )}
              <FormFooter
                contentColumns={{ desktop: 6, tabletLandscape: 6 }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () =>
                    trackTextButtonClick(PageTitle.AdditionalQuestions, next_button_text),
                }}
                pageTitle={PageTitle.AdditionalQuestions}
              />
            </div>
          )
      )}
    </form>
  );
};

export default AdditionalQuestionsForm;
